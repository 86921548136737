<template>
  <div id="base-plan">
    <div class="row">
      <div class="col-md-6">
        <h5>
          From: {{ getScheduleTemData[0].schedule_date | dateFormat }} To:
          {{
            getScheduleTemData[getScheduleTemData.length - 1].schedule_date
              | dateFormat
          }}
        </h5>
      </div>
      <div class="col-md-6" v-if="results.length > 0">
        <button
          ref="kt_submit"
          class="btn btn-success mt-8"
          style="float: right"
          @click="storeScheduleBasePlan()"
        >
          {{ btnName }}
        </button>
        <button
          class="btn btn-danger mt-8 mr-4"
          style="float: right"
          @click="goBack()"
        >
          Go Back
        </button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12" style="background: white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Start</th>
              <th scope="col">Interval</th>
              <th scope="col">End</th>

              <th scope="col">No.of Applicants</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <th>
                <input
                  v-model="item.is_woking_time"
                  type="checkbox"
                  name=""
                  id=""
                />
              </th>

              <td>
                {{ item.start_time }}
              </td>
              <th scope="row">{{ item.interval }}</th>
              <td>
                {{ item.end_time }}
              </td>

              <td>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  v-model="item.no_of_applicant"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  FETCH_C_APPOINTMENT_BASE_PLAN,
  STORE_SCHEDULE_BASEPLAN,
  FETCH_SCHEDULE_BASE_PLAN
} from "@/core/services/store/actions.type";
export default {
  computed: {
    ...mapGetters(["currentUser", "getScheduleTemData"])
  },
  data() {
    return {
      results: [],
      btnName: "Save"
    };
  },
  created() {
    var cRouteName = this.$route.name;
    if (cRouteName == "client.appointment.schedule.baseplan.update") {
      this.btnName = "Update";
      this.$store
        .dispatch(FETCH_SCHEDULE_BASE_PLAN, this.$route.params.id)
        .then(res => {
          this.results = res.data;
          this.results.forEach(element => {
            var fullDateStart =
              this.getScheduleTemData[0].schedule_date +
              " " +
              element.start_time;

            var localStart = moment(fullDateStart).format("HH:mm");

            var fullDateEnd =
              this.getScheduleTemData[0].schedule_date + " " + element.end_time;

            var localEnd = moment(fullDateEnd).format("HH:mm");
            element.start_time = localStart;
            element.end_time = localEnd;
          });
        })
        .catch(errs => {
          this.$toastr.e(errs.detail);
        });
    } else {
      this.btnName = "Save";
      this.$store
        .dispatch(FETCH_C_APPOINTMENT_BASE_PLAN)
        .then(res => {
          this.results = res.data;
          this.results.forEach(element => {
            var fullDateStart =
              this.getScheduleTemData[0].schedule_date +
              " " +
              element.start_time;

            var localStart = moment(fullDateStart).format("HH:mm");

            var fullDateEnd =
              this.getScheduleTemData[0].schedule_date + " " + element.end_time;

            var localEnd = moment(fullDateEnd).format("HH:mm");
            element.start_time = localStart;
            element.end_time = localEnd;
          });
        })
        .catch(errs => {
          this.$toastr.e(errs.detail);
        });
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "client.appointment.schedule" });
    },
    storeScheduleBasePlan() {
      const submitButton = this.$refs["kt_submit"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      // this.results.forEach(element => {
      //   var dateTimeEnd =
      //     this.getScheduleTemData[0].schedule_date + " " + element.end_time;
      //   var dateTimeStart =
      //     this.getScheduleTemData[0].schedule_date + " " + element.start_time;
      //   const localEnd = this.$moment(dateTimeEnd)
      //     .utc()
      //     .format("HH:mm");
      //   const localStart = this.$moment(dateTimeStart)
      //     .utc()
      //     .format("HH:mm");

      //   element.start_time = localStart;
      //   element.end_time = localEnd;
      // });
      // console.log(this.results);
      var formData = {
        schedules: this.getScheduleTemData,
        base_plans: this.results,
        is_Update:
          this.$route.name == "client.appointment.schedule.baseplan.update"
            ? true
            : false
      };
      this.$store
        .dispatch(STORE_SCHEDULE_BASEPLAN, formData)
        .then(res => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({ name: "client.appointment.schedule" });
        })
        .catch(errs => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e(errs.detail);
        });
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    timeFormat: function(value, date) {
      var fullDate = date + " " + value;
      var time = moment(fullDate).format("HH:mm");
      return time;
    }
  }
};
</script>
